import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
const config = {
  apiKey: "AIzaSyD_NAhVA3Mydzg7en7K0uWSyvKU5T6M_F0",
  authDomain: "door-to-door-diary.firebaseapp.com",
  databaseURL: "https://door-to-door-diary.firebaseio.com",
  projectId: "door-to-door-diary",
  storageBucket: "door-to-door-diary.appspot.com",
  messagingSenderId: "644281003962",
  appId: "1:644281003962:web:9d9ccd17d709083d"
};
firebase.initializeApp(config);
export const db = firebase.firestore();
export default firebase;
