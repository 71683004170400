import React, { useState } from "react"
import { db } from "./../../firebase"
import CustomerBase from "./CustomerBase"
import moment from "moment"

const AddCustomer = props => {
  const [name, setName] = useState("")
  const [address, setAddress] = useState("")
  const [lastCompleted, setLastCompleted] = useState("")
  const [nextDue, setNextDue] = useState("")
  const [jobInterval, setJobInterval] = useState(0)
  const [price, setPrice] = useState(0)
  const [firstPaid, setFirstPaid] = useState(true)
  const [notes, setNotes] = useState("")
  const [loading, setLoading] = useState(false)

  const handleChange = (type, event) => {
    switch (type) {
      case "name":
        setName(event.target.value)
        break
      case "address":
        setAddress(event.target.value)
        break
      case "lastCompleted":
        setLastCompleted(event.target.value)
        if (jobInterval) {
          setNextDue(
            moment(event.target.value)
              .add(jobInterval, "w")
              .format("YYYY-MM-DD")
          )
        }
        break
      case "nextDue":
        setNextDue(event.target.value)
        break
      case "jobInterval":
        setJobInterval(event.target.value)
        if (lastCompleted) {
          setNextDue(
            moment(lastCompleted)
              .add(jobInterval, "w")
              .format("YYYY-MM-DD")
          )
        }
        break
      case "price":
        setPrice(event.target.value)
        break
      case "firstPaid":
        setFirstPaid(!firstPaid)
        break
      case "notes":
        setNotes(event.target.value)
        break
      default:
        break
    }
  }
  const handleSubmit = event => {
    event.preventDefault()
    if (name && address && jobInterval && lastCompleted && nextDue && price) {
      setLoading(true)
      db.collection(`users/${props.user.uid}/customers`)
        .add({
          name,
          address,
          interval: jobInterval,
          lastCompleted,
          nextDue,
          price,
          owed: firstPaid ? 0 : price,
          notes
        })
        .then(() => {
          setLoading(false)
          props.handleSnackBarOpen("Customer Added", "success")
          props.handleClose("customer")
        })
        .catch(err => {
          setLoading(false)
          props.handleSnackBarOpen(
            `Error Adding Customer: ${err.message}`,
            "success"
          )
        })
    } else {
      props.handleSnackBarOpen("Please fill in all required fields", "error")
    }
  }
  return (
    <CustomerBase
      loading={loading}
      handleClose={props.handleClose}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      name={name}
      address={address}
      lastCompleted={lastCompleted}
      nextDue={nextDue}
      jobInterval={jobInterval}
      price={price}
      firstPaid={firstPaid}
      notes={notes}
      type="Add"
    />
  )
}

export default AddCustomer
