import React, { useState, useEffect } from "react"
import SignIn from "./SignIn"
import Home from "./Home"
import firebase from "./../firebase"
import { Button } from "@material-ui/core"
const App = () => {
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [user, setUser] = useState(null)

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(user)
        setIsSignedIn(true)
      } else {
        setIsSignedIn(false)
      }
    })
  })
  return isSignedIn ? (
    <div>
      <Button
        onClick={() => {
          firebase.auth().signOut()
        }}
      >
        Logout
      </Button>
      <Home user={user} />
    </div>
  ) : (
      <SignIn setIsSignedIn={setIsSignedIn} />
    )
}

export default App
