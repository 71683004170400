import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { PersonAdd, People } from '@material-ui/icons'
import { lighten, makeStyles } from '@material-ui/core/styles'
import { MTableToolbar } from 'material-table'

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
  },
  title: {
    flex: '0 0 auto',
  },
}))

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles()

  return (
    <div>
      <MTableToolbar {...props} />
      <Toolbar className={classes.root}>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <Tooltip title="Add Customer">
            <IconButton
              aria-label="Filter list"
              onClick={() => props.handleOpenAdd()}
            >
              <PersonAdd />
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>
    </div>
  )
}
export default EnhancedTableToolbar
