import React from 'react'
import {
  TextField,
  makeStyles,
  InputAdornment,
  CardHeader,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Select,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
  },
  container: {
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  input: { color: 'black' },
  topInnerContainer: {
    width: '100%',
    display: 'flex',
  },
  middleContainer: { display: 'flex' },
  notesContainer: {
    width: '100%',
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
  },
  hasPaidCheckBox: {
    marginLeft: theme.spacing(1),
  },
  spinnerContainer: {
    width: '100%',
    height: '25%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    margin: theme.spacing(2),
  },
  select: { width: '25%' },
  formControl: {
    display: 'flex',
    marginLeft: '9px',
    marginRight: '-7px',
    marginTop: '8px',
  },
}))

const CustomerBase = props => {
  const classes = useStyles()
  return (
    <div>
      {props.loading ? (
        <div className={classes.spinnerContainer}>
          <CircularProgress
            className={classes.spinner}
            size={60}
            disableShrink={true}
            thickness={4}
          />
        </div>
      ) : (
          <div>
            <CardHeader
              title={`${props.type} Customer`}
              titleTypographyProps={{ className: classes.title }}
            />
            <form
              className={classes.container}
              noValidate
              autoComplete="off"
              onSubmit={props.handleSubmit}
            >
              <div className={classes.topInnerContainer}>
                <TextField
                  autoComplete="user-password"
                  required
                  variant="outlined"
                  disabled={props.type === 'View'}
                  id="customername"
                  label="Name"
                  className={classes.textField}
                  InputProps={{ classes: { input: classes.input } }}
                  value={props.name}
                  onChange={e => props.handleChange('name', e)}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  variant="outlined"
                  disabled={props.type === 'View'}
                  required
                  autoComplete="user-password"
                  id="address"
                  label="Address"
                  className={classes.textField}
                  InputProps={{ classes: { input: classes.input } }}
                  value={props.address}
                  onChange={e => props.handleChange('address', e)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
              </div>
              <div className={classes.middleContainer}>
                <TextField
                  id="jobInterval"
                  required
                  className={classes.textField}
                  variant="outlined"
                  disabled={props.type === 'View'}
                  autoComplete="user-password"
                  label="Interval"
                  value={props.jobInterval}
                  type="number"
                  margin="normal"
                  onChange={e => props.handleChange('jobInterval', e)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Wks</InputAdornment>
                    ),
                    classes: { input: classes.input },
                  }}
                />

                <TextField
                  variant="outlined"
                  disabled={props.type === 'View'}
                  autoComplete="user-password"
                  required
                  id="lastCompleted"
                  label="Last Completed"
                  type="date"
                  InputProps={{ classes: { input: classes.input } }}
                  value={props.lastCompleted}
                  margin="normal"
                  onChange={e => props.handleChange('lastCompleted', e)}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  variant="outlined"
                  disabled={props.type === 'View'}
                  autoComplete="user-password"
                  required
                  id="nextDue"
                  label="Next Due"
                  type="date"
                  InputProps={{ classes: { input: classes.input } }}
                  value={props.nextDue}
                  margin="normal"
                  onChange={e => props.handleChange('nextDue', e)}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="price"
                  className={classes.textField}
                  required
                  variant="outlined"
                  disabled={props.type === 'View'}
                  autoComplete="user-password"
                  label="Price"
                  value={props.price}
                  type="number"
                  margin="normal"
                  onChange={e => props.handleChange('price', e)}
                  InputProps={{
                    classes: { input: classes.input },
                    startAdornment: (
                      <InputAdornment position="start">€</InputAdornment>
                    ),
                  }}
                />

                {props.type === 'Add' ? (
                  <FormControlLabel
                    className={classes.hasPaidCheckBox}
                    control={
                      <Checkbox
                        checked={props.firstPaid}
                        onChange={() => props.handleChange('firstPaid')}
                        value="firstPaid"
                        color="primary"
                      />
                    }
                    label="Has Paid?"
                  />
                ) : null}
              </div>
              <div className={classes.notesContainer}>
                <TextField
                  id="notes"
                  label="Notes"
                  multiline
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  disabled={props.type === 'View'}
                  autoComplete="user-password"
                  fullWidth
                  InputProps={{ classes: { input: classes.input } }}
                  value={props.notes}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => props.handleChange('notes', e)}
                />
              </div>
              <div className={classes.buttonContainer}>
                {props.type !== 'View' ? (
                  <Button type="submit">{props.type}</Button>
                ) : null}
                <Button onClick={() => props.handleClose('customer')}>
                  {props.type === 'View' ? 'Close' : 'Cancel'}
                </Button>
              </div>
            </form>
          </div>
        )}
    </div>
  )
}

export default CustomerBase
