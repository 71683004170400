import React, { useState, useEffect } from "react"
import { db } from "./../../firebase"
import CustomerBase from "./CustomerBase"
import moment from "moment"
const EditCustomer = props => {
  const [name, setName] = useState("")
  const [address, setAddress] = useState("")
  const [lastCompleted, setLastCompleted] = useState("")
  const [nextDue, setNextDue] = useState("")
  const [jobInterval, setJobInterval] = useState(0)
  const [price, setPrice] = useState(0)
  const [notes, setNotes] = useState("")
  const customerRef = db.collection(`users/${props.user.uid}/customers`).doc(props.selectedId)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    customerRef.get().then(doc => {
      const {
        name,
        address,
        lastCompleted,
        nextDue,
        interval,
        price,
        notes
      } = doc.data()
      setName(name)
      setAddress(address)
      setLastCompleted(lastCompleted)
      setNextDue(nextDue)
      setJobInterval(interval)
      setPrice(price)
      setNotes(notes)
      setLoading(false)
    })
  }, [])
  const handleChange = (type, event) => {
    event.preventDefault()
    switch (type) {
      case "name":
        setName(event.target.value)
        break
      case "address":
        setAddress(event.target.value)
        break
      case "lastCompleted":
        setLastCompleted(event.target.value)
        if (jobInterval) {
          setNextDue(
            moment(event.target.value)
              .add(jobInterval, "w")
              .format("YYYY-MM-DD")
          )
        }
        break
      case "nextDue":
        setNextDue(event.target.value)
        break
      case "jobInterval":
        setJobInterval(event.target.value)
        if (lastCompleted) {
          setNextDue(
            moment(lastCompleted)
              .add(jobInterval, "w")
              .format("YYYY-MM-DD")
          )
        }
        break
      case "price":
        setPrice(event.target.value)
        break
      case "notes":
        setNotes(event.target.value)
        break
      default:
        break
    }
  }
  const handleSubmit = event => {
    event.preventDefault()
    if (name && address && jobInterval && lastCompleted && nextDue && price) {
      setLoading(true)
      customerRef
        .update({
          name,
          address,
          interval: jobInterval,
          lastCompleted,
          nextDue,
          price,
          notes
        })
        .then(() => {
          setLoading(false)
          props.handleSnackBarOpen("Customer Updated", "success")
          props.handleClose("customer")
        })
        .catch(err => {
          setLoading(false)
          props.handleSnackBarOpen(
            `Error Updating Customer: ${err.message}`,
            "error"
          )
        })
    } else {
      props.handleSnackBarOpen("Please fill in all required fields", "error")
    }
  }
  return (
    <CustomerBase
      loading={loading}
      handleClose={props.handleClose}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      name={name}
      address={address}
      lastCompleted={lastCompleted}
      nextDue={nextDue}
      jobInterval={jobInterval}
      price={price}
      notes={notes}
      type="Edit"
    />
  )
}

export default EditCustomer
