import React, { useState } from "react"
import {
  TextField,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Button
} from "@material-ui/core"
import { db } from "../firebase"
import moment from "moment"
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column"
  },

  textField: {
    paddingBottom: theme.spacing(2)
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    paddingBottom: theme.spacing(2)
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around"
  }
}))
const Done = props => {
  const [dateCompleted, setDateCompleted] = useState(
    moment().format("YYYY-MM-DD")
  )
  const [hasPaid, setHasPaid] = useState(true)

  const handleChange = (type, event) => {
    switch (type) {
      case "dateCompleted":
        setDateCompleted(event.target.value)
        break
      case "hasPaid":
        setHasPaid(!hasPaid)
        break
      default:
        break
    }
  }
  const handleDone = () => {
    if (dateCompleted) {
      const selectedCustomersRef = db
        .collection(`users/${props.user.uid}/customers`)
        .doc(props.selectedId)
      return db
        .runTransaction(transaction => {
          return transaction.get(selectedCustomersRef).then(doc => {
            if (!doc.exists) {
              let err = { message: "Document does not exist!" }
              throw err
            }
            const { interval, owed, price } = doc.data()
            let newOwed = owed
            if (hasPaid) {
              newOwed = newOwed === 0 ? 0 : newOwed - price
            } else {
              newOwed = newOwed + parseInt(price)
            }
            transaction.update(selectedCustomersRef, {
              owed: newOwed,
              lastCompleted: dateCompleted,
              nextDue: moment(dateCompleted)
                .add(interval, "w")
                .format("YYYY-MM-DD")
            })
          })
        })
        .then(() => {
          props.handleSnackBarOpen("Job Done", "success")
          props.handleClose("done")
        })
        .catch(err => {
          props.handleSnackBarOpen(`Error:${err.message}`, "error")
        })
    }
  }
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <TextField
        variant="outlined"
        autoComplete="user-password"
        id="dateCompleted"
        label="Date Completed"
        type="date"
        value={dateCompleted}
        margin="normal"
        onChange={e => handleChange("dateCompleted", e)}
        className={classes.textField}
        InputLabelProps={{
          shrink: true
        }}
      />
      <div className={classes.inputContainer}>
        <FormControlLabel
          className={classes.hasPaidCheckBox}
          control={
            <Checkbox
              checked={hasPaid}
              onChange={() => handleChange("hasPaid")}
              value="HasPaid"
              color="primary"
            />
          }
          label="Has Paid?"
        />
        {/* <FormControlLabel
          className={classes.hasPaidCheckBox}
          control={
            <Checkbox
              checked={fullPaid}
              onChange={() => handleChange("fullPaid")}
              value="fullPaid"
              color="primary"
            />
          }
          label="Paid all money owed?"
        /> */}
      </div>
      <div className={classes.buttonContainer}>
        <Button onClick={() => handleDone()} disabled={props.loading}>
          Done
        </Button>
        <Button
          onClick={() => props.handleClose("done")}
          disabled={props.loading}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}
export default Done
