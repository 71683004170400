import React, { useState } from 'react'
import Table from './Table/Table'
import CustomizedSnackbar from './Snackbar'
import { Modal, makeStyles } from '@material-ui/core'
import AddCustomer from './Customer/AddCustomer'
import EditCustomer from './Customer/EditCustomer'
import ViewCustomer from './Customer/ViewCustomer'
import Done from './Done'
import { db } from '../firebase'
import CustomDialog from './Dialog'

const modalStyle = () => {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  }
}

const useStyles = makeStyles(theme => ({
  addModalPaper: {
    position: 'absolute',
    width: '60%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    outline: 'none',
  },
  doneModalPaper: {
    position: 'absolute',
    // width: "20%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    outline: 'none',
  },
}))

const Home = ({ user }) => {
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [snackBarType, setSnackBarType] = useState('')
  const [openCustomer, setOpenCustomer] = useState(false)
  const [openDone, setOpenDone] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [selectedId, setSelectedId] = useState('')
  const [loading, setLoading] = useState(false)
  const [alertType, setAlertType] = useState('')
  let customersRef = db.collection(`users/${user.uid}/customers`)

  const handleSnackBarOpen = (message, type) => {
    setSnackBarType(type)
    setSnackBarMessage(message)
    setOpenSnackBar(true)
  }

  const handleSnackBarClose = () => {
    setOpenSnackBar(false)
  }

  const handleOpen = type => {
    switch (type) {
      case 'customer':
        setOpenCustomer(true)
        break
      case 'done':
        setOpenDone(true)
        break
      case 'alert':
        setOpenAlert(true)
        break
      default:
        break
    }
  }

  const handleClose = type => {
    switch (type) {
      case 'customer':
        setOpenCustomer(false)
        break
      case 'done':
        setOpenDone(false)
        break
      case 'alert':
        setOpenAlert(false)
        break
      default:
        break
    }
  }

  const pickModalToShow = () => {
    let modalToShow = null
    switch (modalType) {
      case 'Add':
        modalToShow = (
          <AddCustomer
            handleClose={handleClose}
            handleSnackBarOpen={handleSnackBarOpen}
            user={user}
          />
        )
        break
      case 'Edit':
        modalToShow = (
          <EditCustomer
            selectedId={selectedId}
            handleClose={handleClose}
            handleSnackBarOpen={handleSnackBarOpen}
            user={user}
          />
        )
        break
      case 'View':
        modalToShow = (
          <ViewCustomer
            selectedId={selectedId}
            handleClose={handleClose}
            handleSnackBarOpen={handleSnackBarOpen}
            user={user}
          />
        )
        break
      default:
        break
    }
    return modalToShow
  }

  const handleSettleUp = () => {
    setLoading(true)
    customersRef
      .doc(selectedId)
      .update({ owed: 0 })
      .then(() => {
        setLoading(false)
        handleSnackBarOpen('Settle Up Complete')
        handleClose('alert')
      })
      .catch(err => {
        setLoading(false)
        handleSnackBarOpen(err.message)
        handleClose('alert')
      })
  }

  const handleDelete = () => {
    if (selectedId) {
      setLoading(true)
      customersRef
        .doc(selectedId)
        .delete()
        .then(() => {
          setLoading(false)
          handleClose('alert')
          handleSnackBarOpen('Customer Deleted', 'success')
        })
        .catch(err => {
          setLoading(false)
          handleClose('alert')
          handleSnackBarOpen(`Error Deleting Customer: ${err.message}`, 'error')
        })
    }
  }

  const handleOpenView = key => {
    setSelectedId(key)
    setModalType('View')
    handleOpen('customer')
  }

  const handleOpenAdd = () => {
    setModalType('Add')
    handleOpen('customer')
  }

  const handleOpenEdit = key => {
    setSelectedId(key)
    setModalType('Edit')
    handleOpen('customer')
  }

  const handleOpenDone = key => {
    setSelectedId(key)
    handleOpen('done')
  }

  const handleOpenSettle = key => {
    setSelectedId(key)
    setAlertType('SettleUp')
    handleOpen('alert')
  }

  const handleOpenDelete = key => {
    setSelectedId(key)
    setAlertType('Delete')
    handleOpen('alert')
  }

  const classes = useStyles()
  return (
    <div>
      <CustomizedSnackbar
        snackBarMessage={snackBarMessage}
        openSnackBar={openSnackBar}
        handleSnackBarClose={handleSnackBarClose}
        snackBarType={snackBarType}
      />
      <Modal
        aria-labelledby="add-customer-modal"
        aria-describedby="add-customer-modal"
        open={openCustomer}
        onClose={() => handleClose('customer')}
      >
        <div style={modalStyle()} className={classes.addModalPaper}>
          {pickModalToShow()}
        </div>
      </Modal>
      <Modal
        aria-labelledby="done-job-modal"
        aria-describedby="done-job-modal"
        open={openDone}
        onClose={() => handleClose('done')}
      >
        <div style={modalStyle()} className={classes.doneModalPaper}>
          <Done
            loading={loading}
            handleClose={handleClose}
            selectedId={selectedId}
            handleSnackBarOpen={handleSnackBarOpen}
            user={user}
          />
        </div>
      </Modal>
      <CustomDialog
        open={openAlert}
        loading={loading}
        handleCloseFunction={() => handleClose('alert')}
        handleSubmitFunction={() =>
          alertType === 'SettleUp' ? handleSettleUp() : handleDelete()
        }
        dialogTitle={
          alertType === 'SettleUp'
            ? 'Settle up all money owed?'
            : 'Are you sure you want to delete this customer?'
        }
        acceptButtonMessage={alertType === 'SettleUp' ? 'Settle' : 'Delete'}
      />
      {user && <Table
        handleSnackBarOpen={handleSnackBarOpen}
        handleOpenAdd={handleOpenAdd}
        handleOpenDone={handleOpenDone}
        handleOpenSettle={handleOpenSettle}
        handleOpenEdit={handleOpenEdit}
        handleOpenView={handleOpenView}
        handleOpenDelete={handleOpenDelete}
        setSelectedId={setSelectedId}
        customersRef={customersRef}
      />}
    </div>
  )
}

export default Home
