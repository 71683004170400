import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog
} from "@material-ui/core";

const CustomDialog = props => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.handleCloseFunction()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => props.handleCloseFunction()}
            color="primary"
            disabled={props.loading}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              props.handleSubmitFunction();
            }}
            color="primary"
            autoFocus
            disabled={props.loading}
          >
            {props.acceptButtonMessage}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomDialog;
