import React, { useState, useEffect } from "react"
import { db } from "./../../firebase"
import CustomerBase from "./CustomerBase"

const ViewCustomer = props => {
  const [name, setName] = useState("")
  const [address, setAddress] = useState("")
  const [lastCompleted, setLastCompleted] = useState("")
  const [nextDue, setNextDue] = useState("")
  const [jobInterval, setJobInterval] = useState(0)
  const [price, setPrice] = useState(0)
  const [notes, setNotes] = useState("")
  const [loading, setLoading] = useState(false)
  const customerRef = db.collection(`users/${props.user.uid}/customers`).doc(props.selectedId)
  useEffect(() => {
    setLoading(true)
    customerRef.get().then(doc => {
      const {
        name,
        address,
        lastCompleted,
        nextDue,
        interval,
        price,
        notes
      } = doc.data()
      setName(name)
      setAddress(address)
      setLastCompleted(lastCompleted)
      setNextDue(nextDue)
      setJobInterval(interval)
      setPrice(price)
      setNotes(notes)
      setLoading(false)
    })
  }, [])

  return (
    <CustomerBase
      loading={loading}
      handleClose={props.handleClose}
      name={name}
      address={address}
      lastCompleted={lastCompleted}
      nextDue={nextDue}
      jobInterval={jobInterval}
      price={price}
      notes={notes}
      type="View"
    />
  )
}

export default ViewCustomer
