import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import MaterialTable from 'material-table'
import EnhancedTableToolbar from './TableToolbar'
import { Paper } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import {
  Search,
  OfflinePin,
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  ViewColumn,
  Pageview,
  DoneOutline,
} from '@material-ui/icons'
import { forwardRef } from 'react'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}))

const headRows = [
  {
    title: 'name',
    field: 'name',
  },
  {
    title: 'address',
    field: 'address',
  },
  {
    title: 'interval',
    field: 'interval',
    type: 'numeric',
  },
  {
    title: 'lastCompleted',
    field: 'lastCompleted',
    type: 'date',
  },
  {
    title: 'nextDue',
    field: 'nextDue',
    type: 'date',
    cellStyle: nextDue => ({
      backgroundColor: moment(nextDue).isBefore(moment())
        ? '#01FE33'
        : '#FEFEFE',
    }),
  },
  {
    title: 'price',
    field: 'price',
    type: 'numeric',
  },
  {
    title: 'owed',
    field: 'owed',
    type: 'numeric',
    cellStyle: owed => {
      return {
        backgroundColor: owed ? '#FE0101' : '#FEFEFE',
        color: owed ? '#FEFEFE' : 'inherit',
      }
    },
  },
]

export default function EnhancedTable({
  handleOpenAdd,
  handleOpenGroup,
  handleOpenView,
  handleOpenSettle,
  handleOpenEdit,
  handleOpenDelete,
  handleOpenDone,
  customersRef,
}) {
  const classes = useStyles()
  const [rows, setRows] = useState([])

  useEffect(() => {
    customersRef.onSnapshot(snap => {
      const data = []
      snap.forEach(doc => {
        data.push({ key: doc.id, ...doc.data() })
      })
      setRows(data)
    })
  }, [customersRef])

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <MaterialTable
            className={classes.table}
            size="medium"
            title="Door To Door Diary"
            columns={headRows}
            data={rows}
            options={{
              actionsColumnIndex: -1,
              pageSize: 15,
              pageSizeOptions: [15, 30, 100],
            }}
            icons={tableIcons}
            components={{
              Toolbar: props => (
                <EnhancedTableToolbar
                  handleOpenAdd={handleOpenAdd}
                  handleOpenGroup={handleOpenGroup}
                  {...props}
                />
              ),
            }}
            actions={[
              {
                icon: () => <Pageview />,
                tooltip: 'View Customer',
                onClick: (event, rowData) => {
                  handleOpenView(rowData.key)
                },
              },
              {
                icon: () => <EditIcon />,
                tooltip: 'Edit Customer',
                onClick: (event, rowData) => {
                  handleOpenEdit(rowData.key)
                },
              },
              {
                icon: () => <DoneOutline />,
                tooltip: 'Done',
                onClick: (event, rowData) => {
                  handleOpenDone(rowData.key)
                },
              },
              {
                icon: () => <OfflinePin />,
                tooltip: 'Settle Up',
                onClick: (event, rowData) => {
                  handleOpenSettle(rowData.key)
                },
              },
              {
                icon: () => <DeleteIcon />,
                tooltip: 'Delete Customer',
                onClick: (event, rowData) => {
                  handleOpenDelete(rowData.key)
                },
              },
            ]}
          ></MaterialTable>
        </div>
      </Paper>
    </div>
  )
}
